import { Box, CircularProgress } from '@mui/material';
import DesktopMainLayout from '../layout/Desktop/DesktopMainLayout';
import ChatList from './components/ChatList';
import ChatHead from './components/ChatHead';
import ChatBox from './components/ChatBox';
import isMobile from '../hooks/isMobile';
import BottomNavbar from '../components/Mobile/BottomNavbar';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserDataContext } from '../routes/Main';
import { RoomData } from '../store/constants/constants';
import { AppDispatch, RootState } from '../store/store';
import { mapUser } from '../store/user/userActions';
import { SocketContext } from '../utils/SocketProvider';
import { setSelectedChat } from '../store/room/roomSlice';
import { setSearchStatus } from '../store/message/messageSlice';
import { dynamicFailed } from '../hooks/useToast';

const ChatRoom = () => {
  const mobileView = isMobile();

  const { userData } = useContext(UserDataContext);

  const chatUser = {
    userId: userData?.data?.id,
    userName: userData?.data?.name,
  };

  const dispatch: AppDispatch = useDispatch();
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const error = useSelector((state: RootState) => state.websocket.error);
  const selectedChat = useSelector((state: RootState) => state.room.selectedChat);

  const socket = useContext(SocketContext);

  const handleChatItemClick = (chat: RoomData) => {
    dispatch(setSelectedChat(chat));
    dispatch(setSearchStatus(false));
  };

  useEffect(() => {
    if (isConnected && socket && chatUser) {
      dispatch(mapUser({ socket, chatUser }));
    }
  }, [isConnected, socket, dispatch]);

  if (!isConnected) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' padding={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    dynamicFailed(`${error}`);
  }

  return (
    <>
      {mobileView ? (
        <Box
          display='flex'
          flexDirection='column'
          height='100vh'
          position='relative'
          overflow='hidden'
        >
          {!selectedChat && <ChatHead />}
          {!selectedChat ? (
            <ChatList onChatItemClick={handleChatItemClick} />
          ) : (
            <ChatBox chat={selectedChat} />
          )}
          {!selectedChat && <BottomNavbar />}
        </Box>
      ) : (
        <DesktopMainLayout>
          <Box
            bgcolor='white'
            position='fixed'
            left='85px'
            right='0px'
            top='0px'
            bottom='-25px'
            p='14px 0 14px 14px'
            overflow='hidden'
            display='flex'
            flexDirection='row'
            gap='10px'
          >
            {/* left side== navbar and chatuser item */}
            <Box display='flex' flexDirection='column' gap='8px' width='25vw'>
              <ChatHead />
              <ChatList onChatItemClick={handleChatItemClick} />
            </Box>

            {/* right side chatting */}
            <Box width='75vw' display='flex' flexDirection='column'>
              <ChatBox chat={selectedChat} />
            </Box>
          </Box>
        </DesktopMainLayout>
      )}
    </>
  );
};

export default ChatRoom;
