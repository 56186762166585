import { configureStore } from '@reduxjs/toolkit';
import websocketReducer from './websocket/websocketSlice';
import userReducer from './user/userSlice';
import messageReducer from './message/messageSlice';
import roomReducer from './room/roomSlice';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    websocket: websocketReducer,
    user: userReducer,
    message: messageReducer,
    room: roomReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
