import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  sendMessage,
  getMessage,
  replyMessage,
  deleteMessage,
  sendAttachment,
  pinMessage,
  removePinMessage,
  getMediaCollection,
  setSearchResults,
  forwardMessage,
  sendChatLog,
  setPinnedMessages,
} from './messageSlice';
import {
  MessageData,
  PinnedMessageData,
  SearchResponse,
  MediaCollectionData,
  ForwardMessageResponse,
  ChatLogData,
} from '../constants/constants';
import { Socket } from 'socket.io-client';

interface GetMediaCollectionArgs {
  socket: Socket;
  roomId: number;
}

interface ForwardMessageArgs {
  socket: Socket;
  forwardData: {
    roomId: number;
    messageId: number;
  };
}

interface SendMessageArgs {
  socket: Socket;
  message: {
    senderId: number;
    content: string;
    roomId: number;
  };
}

interface SendChatLogArgs {
  socket: Socket;
  logData: {
    roomId: number;
    content: string;
  };
}

interface EditMessageArgs {
  socket: Socket;
  edit: {
    id: number;
    senderId: number;
    content: string;
    roomId: number;
  };
}

interface ReplyMessageArgs {
  socket: Socket;
  reply: {
    messageId: number;
    senderId: number;
    content: string;
    roomId: number;
  };
}

interface DeleteMessageArgs {
  socket: Socket;
  deleteData: {
    id: number;
    roomId: number;
  };
}

interface SeenMessageArgs {
  socket: Socket;
  roomId: number;
  messageId: number[];
}

interface GetMessageArgs {
  message1: {
    roomId?: number;
    userId: number;
    total?: number;
    page?: number;
    limit?: number;
  };
  socket: Socket;
}

interface PinMessageArgs {
  socket: Socket;
  pinData: {
    roomId: number;
    messageId: number;
  };
}

interface RemovePinMessageArgs {
  socket: Socket;
  pinnedMessageId: number;
}

interface SendAttachmentArgs {
  socket: Socket;
  attachment: {
    base64String: string;
    fileName: string;
    extension: string;
    roomId: number;
  };
}

interface SearchMessagesArgs {
  socket: Socket;
  roomId: number;
  query: string;
}

interface TypingIndicatorArgs {
  socket: Socket;
  payload: {
    roomId: number;
  };
}

export const getMediaCollectionAction = createAsyncThunk(
  'message/getMediaCollection',
  async ({ socket, roomId }: GetMediaCollectionArgs, { dispatch }) => {
    try {
      socket.emit('getMediaCollection', { roomId });

      socket.on('getMediaCollection', (data: MediaCollectionData) => {
        dispatch(getMediaCollection(data));
      });
    } catch (error) {
      console.error('Error in fetching media collection:', error);
      throw error;
    }
  },
);

export const pinMessageAction = createAsyncThunk(
  'message/pinMessage',
  async ({ socket, pinData }: PinMessageArgs, { dispatch }) => {
    try {
      socket.emit('pinMessage', pinData);

      socket.on('pinMessage', (data: PinnedMessageData) => {
        dispatch(pinMessage(data));
      });
    } catch (error) {
      console.error('Error in pinning message:', error);
      throw error;
    }
  },
);

export const removePinMessageAction = createAsyncThunk(
  'message/removePinMessage',
  async ({ socket, pinnedMessageId }: RemovePinMessageArgs, { dispatch }) => {
    try {
      socket.emit('removePinMessage', { id: pinnedMessageId });
      socket.on('removePinMessage', () => {
        dispatch(removePinMessage());
      });
    } catch (error) {
      console.error('Error in removing pinned message:', error);
      throw error;
    }
  },
);

export const sendMessageAction = createAsyncThunk(
  'message/sendMessage',
  async ({ socket, message }: SendMessageArgs, { dispatch }) => {
    try {
      socket.emit('textMessage', message);
      dispatch(sendMessage(message));
    } catch (error) {
      console.error('Error in sending message:', error);
      throw error;
    }
  },
);

export const sendChatLogAction = createAsyncThunk(
  'message/sendChatLog',
  async ({ socket, logData }: SendChatLogArgs, { dispatch }) => {
    try {
      socket.emit('logMessage', logData);

      socket.on('message', (data: ChatLogData) => {
        dispatch(sendChatLog(data));
      });
    } catch (error) {
      console.error('Error in sending chat log:', error);
      throw error;
    }
  },
);

export const replyMessageAction = createAsyncThunk(
  'message/replyMessage',
  async ({ socket, reply }: ReplyMessageArgs, { dispatch }) => {
    try {
      socket.emit('replyMessage', reply);
      dispatch(replyMessage(reply));
    } catch (error) {
      console.error('Error in replying to message:', error);
      throw error;
    }
  },
);

export const deleteMessageAction = createAsyncThunk(
  'message/deleteMessage',
  async ({ socket, deleteData }: DeleteMessageArgs, { dispatch }) => {
    try {
      socket.emit('deleteMessage', deleteData);
      dispatch(deleteMessage(deleteData.id));
    } catch (error) {
      console.error('Error in deleting message:', error);
      throw error;
    }
  },
);

export const markMessageAsSeenAction = createAsyncThunk(
  'message/markMessageAsSeen',
  async ({ socket, roomId, messageId }: SeenMessageArgs) => {
    try {
      console.log('message', messageId);
      socket.emit('viewMessage', { roomId, messageId });
    } catch (error) {
      console.error('Error marking message as seen:', error);
      throw error;
    }
  },
);

export const getMessageAction = createAsyncThunk(
  'message/getMessage',
  async ({ socket, message1 }: GetMessageArgs, { dispatch }) => {
    try {
      socket.emit('getMessage', message1);
      socket.on(
        'message',
        (data: {
          data: MessageData[];
          total: number;
          page: number;
          limit: number;
          pinMessage: PinnedMessageData | null;
        }) => {
          dispatch(getMessage(data));
        },
      );
    } catch (error) {
      console.error('Error in fetching messages:', error);
      throw error;
    }
  },
);

export const editMessageAction = createAsyncThunk(
  'message/editMessage',
  async ({ socket, edit }: EditMessageArgs) => {
    try {
      console.log('edit', edit);
      socket.emit('editMessage', edit);
    } catch (error) {
      console.error('Error editing message:', error);
      throw error;
    }
  },
);

export const sendAttachmentAction = createAsyncThunk(
  'message/sendAttachment',
  async ({ socket, attachment }: SendAttachmentArgs, { dispatch }) => {
    try {
      socket.emit('attachment', attachment);
      dispatch(sendAttachment(attachment));
    } catch (error) {
      console.error('Error in sending attachment:', error);
      throw error;
    }
  },
);

export const searchMessages = createAsyncThunk(
  'message/searchMessages',
  async ({ socket, roomId, query }: SearchMessagesArgs, { dispatch }) => {
    try {
      // console.log('SearchingMSg', roomId, query);
      socket.emit('searchMessage', { roomId, query });

      socket.once('searchMessage', (response: SearchResponse) => {
        console.log(response);
        dispatch(setSearchResults(response));
      });
    } catch (error) {
      console.error('Error in searching messages:', error);
      throw error;
    }
  },
);

export const forwardMessageAction = createAsyncThunk(
  'message/forwardMessage',
  async ({ socket, forwardData }: ForwardMessageArgs, { dispatch }) => {
    try {
      socket.emit('forwardMessage', forwardData);

      socket.on('forwardMessage', (data: ForwardMessageResponse) => {
        dispatch(forwardMessage(data));
      });
    } catch (error) {
      console.error('Error in forwarding message:', error);
      throw error;
    }
  },
);

export const startTypingIndicator = createAsyncThunk(
  'message/startTyping',
  async ({ socket, payload }: TypingIndicatorArgs) => {
    try {
      socket.emit('startTyping', payload);
    } catch (error) {
      console.error('Error in typing indication:', error);
      throw error;
    }
  },
);

export const stopTypingIndicator = createAsyncThunk(
  'message/stopTyping',
  async ({ socket, payload }: TypingIndicatorArgs) => {
    try {
      socket.emit('stopTyping', payload);
    } catch (error) {
      console.error('Error in stop typing indication:', error);
      throw error;
    }
  },
);

export const getPinnedMessageAction = createAsyncThunk(
  'message/getPinnedMessage',
  async ({ socket, roomId }: { socket: Socket; roomId: number }, { dispatch }) => {
    try {
      socket.emit('getPinMessage', { roomId });

      socket.on('pinMessage', (messages: MessageData[]) => {
        dispatch(setPinnedMessages(messages));
      });
    } catch (error) {
      console.error('Error fetching pinned message:', error);
      throw error;
    }
  },
);
