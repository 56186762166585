import React, { useState, useContext, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { getMediaCollectionAction, sendChatLogAction } from '.././../store/message/messageActions';
import { AppDispatch, RootState } from '.././../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Drawer,
  IconButton,
  Avatar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Tabs,
  Tab,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DownloadIcon from '@mui/icons-material/Download';
import PushPinIcon from '@mui/icons-material/PushPin';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { styled } from '@mui/material/styles';
import { CustomTabPanel } from '../../components/Attendance/Report';
import isMobile from '../../hooks/isMobile';
import { MessageData, RoomData } from '../../store/constants/constants';
import { UserDataContext } from '../../routes/Main';
import {
  fetchRoomDetails,
  removeParticipants,
  removeRoomAction,
} from '../../store/room/roomAction';
import AddParticipants from './AddParticipants';
import { debounce } from 'lodash';
import ConfirmationDialog from './ConfirmationDialog';
import UpdateRoomDialog from './UpdateRoomDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { setSelectedChat } from '../../store/room/roomSlice';
import { pinMessageAction, removePinMessageAction } from '../../store/message/messageActions';
import ImageCarousel from './ImageCarousel';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
      border: '1px solid currentColor',
    },
  },
}));

interface SettingProps {
  isOpen: boolean;
  onClose: () => void;
  chats: RoomData | null;
  setShowPinnedContext: (show: boolean) => void;
}
const Setting: React.FC<SettingProps> = ({ isOpen, onClose, chats, setShowPinnedContext }) => {
  const dispatch = useDispatch<AppDispatch>();
  const socket = useContext(SocketContext);
  const mediaCollection = useSelector((state: RootState) => state.message.mediaCollection);
  const [isMuted, setIsMuted] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const mobileView = isMobile();
  const { userData } = useContext(UserDataContext);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  // Initials for avatar
  const getInitials = (name: string) => {
    // Trim extra spaces and split the name by spaces
    const parts = name.trim().split(/\s+/);

    if (parts.length === 1) {
      // If there's only one part, return the first two letters of that part
      return parts[0].slice(0, 2).toUpperCase();
    }

    // If there are multiple parts, return the first letter of the first and last parts
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  useEffect(() => {
    if (isOpen && chats?.id && socket) {
      dispatch(getMediaCollectionAction({ socket, roomId: chats.id }));
    }
  }, [isOpen, chats?.id, dispatch, socket]);

  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);
  const fetchRoomDetailsDebounced = debounce(() => {
    if (isOpen && chats?.id && socket) {
      dispatch(fetchRoomDetails({ socket, payload: { id: chats.id } }));
    }
  }, 1000);
  useEffect(() => {
    fetchRoomDetailsDebounced();

    // Cleanup the debounce on unmount
    return () => {
      fetchRoomDetailsDebounced.cancel();
    };
  }, [isOpen, chats?.id, dispatch, socket, currentRoom]);

  const UserPresent = chats?.roomParticipants?.find(
    (participant) => participant.user.userId !== userData.data.id,
  )?.user;

  const isGroup = chats?.roomType === 'group';

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const formatDate = (date: string) => {
    const today = new Date();
    const messageDate = new Date(date);

    if (messageDate.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (
      messageDate.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString()
    ) {
      return 'Yesterday';
    } else {
      return messageDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    }
  };

  const formatTime = (date: string) => {
    return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Add participants Dialog
  const [AddParticipantsOpen, setAddParticipantsOpen] = useState(false);
  const handleOpenAddParticipants = () => {
    setAddParticipantsOpen(true);
  };

  const handleCloseAddParticipants = () => {
    setAddParticipantsOpen(false);
  };

  const getFileIcon = (path: string) => {
    if (/\.(pdf)$/i.test(path)) {
      return 'https://cdn-icons-png.flaticon.com/512/4208/4208479.png';
    } else if (/\.(docx|doc)$/i.test(path)) {
      return 'https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png';
    }
    return 'https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png';
  };

  // Find the role of current user
  const currentUserRole = currentRoom?.roomParticipants.find(
    (participant) => participant.user.userId === userData.data.id,
  )?.userRole;

  // Remove participants
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [participantToRemove, setParticipantToRemove] = useState<number | null>(null);
  const [participantNameToRemove, setParticipantNameToRemove] = useState<string>('');

  const handleRemoveParticipant = (participantId: number, participantName: string) => {
    setParticipantToRemove(participantId);
    setParticipantNameToRemove(participantName);
    setConfirmDialogOpen(true);
  };

  const confirmRemoveParticipant = () => {
    // Get the admin user
    const admin = currentRoom?.roomParticipants?.find(
      (participant) => participant.userRole === 'admin',
    );

    if (socket && chats?.id && participantToRemove && currentRoom) {
      dispatch(
        removeParticipants({
          socket: socket,
          payload: {
            roomId: chats.id,
            participantIds: [participantToRemove],
          },
        }),
      );
      dispatch(
        sendChatLogAction({
          socket: socket,
          logData: {
            roomId: currentRoom.id,
            content: `${admin?.user.userName} removed ${participantNameToRemove}`,
          },
        }),
      );
    }
    setConfirmDialogOpen(false);
    setParticipantToRemove(null);
    setParticipantNameToRemove('');
  };

  const cancelRemoveParticipant = () => {
    setConfirmDialogOpen(false);
    setParticipantToRemove(null);
    setParticipantNameToRemove('');
  };

  // Remove room
  const [removeRoomDialogOpen, setRemoveRoomDialogOpen] = useState(false);
  const [roomNameToRemove, setRoomNameToRemove] = useState<string>('');

  const handleRemoveRoom = (roomName: string) => {
    setRoomNameToRemove(roomName);
    setRemoveRoomDialogOpen(true);
  };

  const confirmRemoveRoom = () => {
    if (socket && currentRoom?.id) {
      dispatch(
        removeRoomAction({
          socket: socket,
          payload: {
            id: currentRoom?.id,
          },
        }),
      );
    }
    setRemoveRoomDialogOpen(false);
    setRoomNameToRemove('');
    dispatch(setSelectedChat(null));
  };

  const cancelRemoveRoom = () => {
    setRemoveRoomDialogOpen(false);
    setRoomNameToRemove('');
  };

  // Update Dialogbox
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenUpdateDialog = () => {
    setIsDialogOpen(true);
  };
  const handleCloseUpdateDialog = () => {
    setIsDialogOpen(false);
  };

  const getFileName = (path: string) => {
    return path.split('/').pop() || '';
  };

  const groupByDate = (items: any[] | undefined) => {
    if (!items) return {};
    return items.reduce((acc: { [key: string]: any[] }, item) => {
      const date = formatDate(item.createdAt);
      if (!acc[date]) acc[date] = [];
      acc[date].push(item);
      return acc;
    }, {});
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength - 3) + '...';
  };

  const sortDates = (dates: string[]) => {
    return dates.sort((a, b) => {
      if (a === 'Today') return -1;
      if (b === 'Today') return 1;
      if (a === 'Yesterday') return -1;
      if (b === 'Yesterday') return 1;
      return new Date(b).getTime() - new Date(a).getTime();
    });
  };

  // Option selection menu

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedItem(null);
  };

  const handleDownload = () => {
    if (selectedItem?.path) {
      window.open(`https://chatroom.enitiation.com/${selectedItem.path}`, '_blank');
    }
    handleMenuClose();
  };

  // for pinning and unpinng message
  const pinnedMessage = useSelector((state: RootState) => state.message.pinMessage);

  const isPinned = (item: MessageData) => {
    return pinnedMessage?.message?.id === item.id;
  };

  const PinIndicator = ({ isPinned }: { isPinned: boolean }) => {
    if (!isPinned) return null;

    return (
      <PushPinIcon
        sx={{
          position: 'absolute',
          top: 4,
          right: 4,
          fontSize: '1.2rem',
          color: 'primary.main',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '50%',
          padding: '2px',
          zIndex: 1,
        }}
      />
    );
  };

  const handlePin = () => {
    if (selectedItem && socket && chats?.id) {
      if (pinnedMessage?.id) {
        dispatch(
          removePinMessageAction({
            socket,
            pinnedMessageId: pinnedMessage?.id,
          }),
        );
        setShowPinnedContext(false);
      } else {
        dispatch(
          pinMessageAction({
            socket,
            pinData: {
              roomId: chats.id,
              messageId: selectedItem.id,
            },
          }),
        );
      }
    }
    handleMenuClose();
  };
  const renderMediaContent = () => {
    const groupedMedia = groupByDate(
      mediaCollection?.attachment?.filter(
        (item) => item.path && /\.(jpg|jpeg|png|gif)$/i.test(item.path),
      ),
    );

    return (
      <Box sx={{ mt: 2, mr: 1 }}>
        {sortDates(Object.keys(groupedMedia)).map((date) => (
          <Box key={date}>
            <Typography variant='body1' sx={{ mt: 2, mb: 1 }}>
              {date}
            </Typography>
            <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap={1}>
              {groupedMedia[date].map((item: any) => (
                <Box key={item.id} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Box
                    sx={{
                      position: 'relative',
                      paddingTop: '100%',
                      bgcolor: '#f5f5f5',
                      borderRadius: '4px',
                      height: '100%',
                      width: '100%',
                      mb: 0.5,
                      cursor: 'pointer',
                      border: isPinned(item) ? '2px solid primary.main' : 'none',
                    }}
                    onClick={() => {
                      const allImages = mediaCollection?.attachment?.filter(
                        (item) => item.path && /\.(jpg|jpeg|png|gif)$/i.test(item.path),
                      );
                      const indexInAll = allImages?.findIndex((img) => img.id === item.id) || 0;
                      setSelectedImageIndex(indexInAll);
                      setCarouselOpen(true);
                    }}
                  >
                    <PinIndicator isPinned={isPinned(item)} />
                    <img
                      src={`https://chatroom.enitiation.com/${item.path}`}
                      alt={`Media ${item.id}`}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.875rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flex: 1,
                        mr: 0.5,
                      }}
                    >
                      {truncateText(getFileName(item.path), 15)}
                    </Typography>
                    <IconButton
                      size='small'
                      sx={{
                        color: 'text.secondary',
                        padding: '2px',
                      }}
                      onClick={(e) => handleMenuClick(e, item)}
                    >
                      <MoreVertIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderFilesContent = () => {
    const groupedFiles = groupByDate(
      mediaCollection?.attachment?.filter(
        (item) => item.path && /\.(docs?x?|pdf)$/i.test(item.path),
      ),
    );

    return (
      <List>
        {sortDates(Object.keys(groupedFiles)).map((date) => (
          <Box key={date}>
            <Typography variant='body1' sx={{ mt: 1 }}>
              {date}
            </Typography>
            {groupedFiles[date].map((item: any) => (
              <ListItem
                sx={{
                  borderBottom: '2px solid lightgray',
                  '&:hover': {
                    backgroundColor: 'grey.300',
                  },
                }}
                key={item.id}
                secondaryAction={
                  <IconButton edge='end' onClick={(e) => handleMenuClick(e, item)}>
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <PinIndicator isPinned={isPinned(item)} />
                <ListItemIcon>
                  <img
                    src={getFileIcon(item.path || '')}
                    alt='File Icon'
                    style={{ width: '40px', height: '40px' }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 'bold' }} noWrap>
                    {truncateText(getFileName(item.path), 40)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      m: 0,
                    }}
                  >
                    <Typography variant='body2'>{item.sender.userName}</Typography>
                    <Typography variant='body2'>{formatTime(item.createdAt)}</Typography>
                  </Box>
                </ListItemText>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    );
  };

  const renderLinksContent = () => {
    const groupedLinks = groupByDate(mediaCollection?.link);

    return (
      <List>
        {sortDates(Object.keys(groupedLinks)).map((date) => (
          <Box key={date}>
            <Typography variant='body1' sx={{ mt: 1 }}>
              {date}
            </Typography>
            {groupedLinks[date].map((item: any) => (
              <ListItem
                sx={{
                  borderBottom: '2px solid lightgray',
                  '&:hover': {
                    backgroundColor: 'grey.300',
                  },
                }}
                key={item.id}
                secondaryAction={
                  <IconButton edge='end' onClick={(e) => handleMenuClick(e, item)}>
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <PinIndicator isPinned={isPinned(item)} />
                <ListItemIcon>
                  <img
                    src='https://cdn-icons-png.flaticon.com/512/455/455691.png'
                    alt='Link Icon'
                    style={{ width: '24px', height: '24px' }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 'bold' }} noWrap>
                    {truncateText(item.link, 40)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      m: 0,
                    }}
                  >
                    <Typography variant='body2'>{item.sender.userName}</Typography>
                    <Typography variant='body2'>{formatTime(item.createdAt)}</Typography>
                  </Box>
                </ListItemText>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    );
  };

  return (
    <>
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: mobileView ? '100%' : '460px',
            borderTopLeftRadius: '16px',
            borderBottomLeftRadius: '16px',
            overflow: 'hidden',
          },
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}
        >
          <Box sx={{ position: 'relative', p: 3, pb: 0 }}>
            <IconButton sx={{ position: 'absolute', top: '0', right: '0' }} onClick={onClose}>
              <CloseIcon />
            </IconButton>

            <Box display='flex' gap='20px'>
              {isGroup ? (
                <>
                  <Avatar
                    src={`https://chatroom.enitiation.com/${chats?.avatar}`}
                    alt={chats?.roomName}
                    sx={{ width: '60px', height: '60px' }}
                  >
                    {getInitials(chats?.roomName || 'Group Chat')}
                  </Avatar>
                  <Box>
                    <Typography variant='h6'>{chats?.roomName}</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {currentRoom?.roomParticipants.length} participants
                    </Typography>
                  </Box>
                  {currentUserRole === 'admin' && (
                    <Box>
                      <IconButton aria-label='edit' onClick={handleOpenUpdateDialog}>
                        <EditIcon sx={{ color: 'black' }} />
                      </IconButton>
                      <IconButton
                        aria-label='delete'
                        onClick={() => {
                          if (currentRoom?.roomName) {
                            handleRemoveRoom(currentRoom?.roomName);
                          }
                        }}
                      >
                        <DeleteIcon sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <StyledBadge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant='dot'
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: UserPresent?.isActive ? '#44b700' : '#767676',
                        color: UserPresent?.isActive ? '#44b700' : '#767676',
                        width: '13px',
                        height: '13px',
                        borderRadius: '10px',
                      },
                      zIndex: '99',
                    }}
                  >
                    <Avatar
                      src={
                        chats?.roomParticipants?.find(
                          (participant) => participant.user.userId !== userData.data.id,
                        )?.user?.profilePictureURL
                      }
                      alt={UserPresent?.userName || 'User'}
                      sx={{ width: '60px', height: '60px' }}
                    >
                      {getInitials(
                        chats?.roomParticipants?.find(
                          (participant) => participant.user.userId !== userData.data.id,
                        )?.user?.userName || 'User',
                      )}
                    </Avatar>
                  </StyledBadge>
                  <Box>
                    <Typography variant='h6'>{UserPresent?.userName || 'Unknown User'}</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {UserPresent?.isActive ? 'Active Now' : 'Offline'}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            <Tabs value={tabValue} onChange={handleTabChange} variant='fullWidth'>
              <Tab label='Media' />
              <Tab label='Files' />
              <Tab label='Links' />
              {isGroup && <Tab label='Participants' />}
            </Tabs>

            <Box
              sx={{
                width: '100%',
                overflow: 'auto',
                height: '70vh',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },

                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '10px',
                },
              }}
            >
              <CustomTabPanel value={tabValue} index={0}>
                {renderMediaContent()}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                {renderFilesContent()}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                {renderLinksContent()}
              </CustomTabPanel>

              {isGroup && (
                <CustomTabPanel value={tabValue} index={3}>
                  <List>
                    {currentUserRole === 'admin' && (
                      <ListItemButton
                        onClick={handleOpenAddParticipants}
                        sx={{
                          borderBottom: '2px solid lightgray',
                          pl: 1,
                          '&:hover': {
                            backgroundColor: 'grey.300',
                          },
                        }}
                      >
                        <ListItemIcon>
                          <Avatar sx={{ width: 45, height: 45 }}>
                            <AddIcon sx={{ width: 35, height: 35 }} />
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText primary='Add Participants' />
                      </ListItemButton>
                    )}
                    {currentRoom?.roomParticipants
                      ?.slice() // create a shallow copy to avoid mutating the original array
                      .sort((a) => (a.userRole === 'admin' ? -1 : 1)) // Sort: admin first
                      .map((participant) => (
                        <ListItem
                          key={participant.user.userId}
                          disablePadding
                          sx={{
                            borderBottom: '2px solid lightgray',
                            '&:hover': {
                              backgroundColor: 'grey.300', // Darker shade from theme palette
                            },
                          }}
                        >
                          <ListItemButton sx={{ pl: 1 }}>
                            <ListItemIcon>
                              <StyledBadge
                                overlap='circular'
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant='dot'
                                sx={{
                                  '& .MuiBadge-badge': {
                                    backgroundColor: participant.user.isActive
                                      ? '#28a745'
                                      : '#808080',
                                    color: participant.user.isActive ? '#28a745' : '#808080',
                                  },
                                }}
                              >
                                <Avatar
                                  src={participant.user.profilePictureURL}
                                  alt={participant.user.userName}
                                  sx={{ width: 45, height: 45 }}
                                >
                                  {getInitials(participant.user.userName || 'Anonymous')}
                                </Avatar>
                              </StyledBadge>
                            </ListItemIcon>
                            <ListItemText
                              primary={participant.user.userName}
                              secondary={participant.userRole}
                            />
                            {participant.userRole !== 'admin' && currentUserRole === 'admin' && (
                              <IconButton
                                edge='end'
                                aria-label='remove'
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent ListItemButton click
                                  handleRemoveParticipant(
                                    participant.user.userId,
                                    participant.user.userName,
                                  );
                                }}
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            )}
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </CustomTabPanel>
              )}
            </Box>
          </Box>

          <List
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              display: 'flex',
              px: 3,
              bgcolor: 'white',
            }}
          >
            <ListItemButton onClick={handleMuteToggle} sx={{ textAlign: 'start' }}>
              <ListItemText primary={isMuted ? 'Unmute Notification' : 'Mute Notification'} />
              {isMuted ? <NotificationsOffIcon /> : <NotificationsIcon />}
            </ListItemButton>
            {isGroup && (
              <ListItemButton>
                <ListItemText sx={{ textAlign: 'end', color: 'red' }} primary='Leave Group' />
              </ListItemButton>
            )}
          </List>
        </Box>
        <AddParticipants open={AddParticipantsOpen} onClose={handleCloseAddParticipants} />
        <ConfirmationDialog
          open={confirmDialogOpen}
          title='Confirm Participant Removal'
          message={`Are you sure you want to remove ${participantNameToRemove} from the group?`}
          onConfirm={confirmRemoveParticipant}
          onCancel={cancelRemoveParticipant}
        />
        <UpdateRoomDialog open={isDialogOpen} onClose={handleCloseUpdateDialog} />
        <ConfirmationDialog
          open={removeRoomDialogOpen}
          title='Confirm Room Removal'
          message={`Are you sure you want to remove ${roomNameToRemove} permanently?`}
          onConfirm={confirmRemoveRoom}
          onCancel={cancelRemoveRoom}
        />

        <ImageCarousel
          open={carouselOpen}
          onClose={() => setCarouselOpen(false)}
          initialIndex={selectedImageIndex}
          images={
            mediaCollection?.attachment?.filter(
              (item) => item.path && /\.(jpg|jpeg|png|gif)$/i.test(item.path),
            ) || []
          }
        />
      </Drawer>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {tabValue !== 2 && (
          <MenuItem onClick={handleDownload}>
            <ListItemIcon>
              <DownloadIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handlePin}>
          <ListItemIcon>
            <PushPinIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>{selectedItem && isPinned(selectedItem) ? 'Unpin' : 'Pin'}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Setting;
