import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import logo from '../../assets/images/sciever_logo.png';
import isMobile from '../../hooks/isMobile';
const ChatHead = () => {
  const mobileView = isMobile();
  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap='20px'
        width='100%'
        padding={mobileView ? '10px ' : '0'}
      >
        <ImageList
          sx={{
            width: '45px',
            height: '45px',
            overflow: 'hidden',
            m: '0px',
          }}
          cols={1}
        >
          <ImageListItem>
            <img src={logo} alt='Sciever Inc. Logo' style={{ width: '45px', height: '45px' }} />
          </ImageListItem>
        </ImageList>

        <Typography variant='h6' fontWeight='bold'>
          Chat Room
        </Typography>
      </Box>
    </>
  );
};

export default ChatHead;
