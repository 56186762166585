import React, { useState, useRef, KeyboardEvent, useContext, useEffect, useCallback } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from '@mui/material';
import { SocketContext } from '../../utils/SocketProvider';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Picker from '@emoji-mart/react';
import { MessageData, RoomData, TypingResponse } from '../../store/constants/constants';
import { UserDataContext } from '../../routes/Main';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { setSelectedMessage, updateTypingStatus } from '../../store/message/messageSlice';
import { startTypingIndicator, stopTypingIndicator } from '../../store/message/messageActions';
import TypingIndicator from './TypingIndicator';

interface ChatInputAreaProps {
  droppedFiles: FileList | null;
  chat: RoomData | null;
  onSendMessage: (message: MessageData) => void;
  onSendAttachment: (attachment: {
    base64String: string;
    fileName: string;
    extension: string;
    roomId: number;
  }) => void;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const ChatInputArea: React.FC<ChatInputAreaProps> = ({
  chat,
  onSendMessage,
  onSendAttachment,
  droppedFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  const textFieldRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [PopoverOpen, setPopoverOpen] = useState(false);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);
  const emojiButtonRef = useRef<HTMLButtonElement>(null);
  const { userData } = useContext(UserDataContext);
  const selectedMessage = useSelector((state: RootState) => state.message.selectedMessage);
  const action = useSelector((state: RootState) => state.message.action);

  const [message, setMessage] = useState(
    action === 'edit' && selectedMessage ? selectedMessage.content : '',
  );
  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const typingUsers = useSelector((state: RootState) => state.message.typingUsers);

  const typingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const typingStartedRef = useRef(false);

  const handleTypingStart = useCallback(() => {
    if (chat?.id && socket && !typingStartedRef.current) {
      typingStartedRef.current = true;

      dispatch(
        startTypingIndicator({
          socket: socket,
          payload: { roomId: chat.id },
        }),
      );
    }
  }, [chat?.id, socket, dispatch]);

  useEffect(() => {
    if (socket) {
      socket.on('startTyping', (response: TypingResponse) => {
        console.log(response); // Verify the response here
        dispatch(updateTypingStatus(response));
      });
      socket.on('stopTyping', (response: TypingResponse) => {
        dispatch(updateTypingStatus(response));
      });
    }

    // Clean up the listener when the component unmounts
    return () => {
      if (socket) {
        socket.off('startTyping');
        socket.off('stopTyping');
      }
    };
  }, [socket, dispatch]);

  const handleTypingStop = useCallback(() => {
    if (chat?.id && socket && typingStartedRef.current) {
      typingStartedRef.current = false;

      dispatch(
        stopTypingIndicator({
          socket: socket,
          payload: { roomId: chat.id },
        }),
      );
    }
  }, [chat?.id, socket, dispatch]);

  useEffect(() => {
    if (action === 'edit' && selectedMessage) {
      setMessage(selectedMessage.content);
    }
  }, [action, selectedMessage]);

  useEffect(() => {
    if (droppedFiles) {
      const newFiles = Array.from(droppedFiles).filter(checkFileSize);
      setSelectedFiles((prev) => [...prev, ...newFiles]);
    }
  }, [droppedFiles]);

  const checkFileSize = (file: File): boolean => {
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage(`${file.name}`);
      setPopoverOpen(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return false;
    }
    return true;
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);

      const validFiles = filesArray.filter(checkFileSize);
      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    if (textFieldRef.current && cursorPosition !== null) {
      const newMessage =
        message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
      setMessage(newMessage);
      const newCursorPosition = cursorPosition + emoji.native.length;
      setCursorPosition(newCursorPosition);
      setTimeout(() => {
        textFieldRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
        textFieldRef.current?.focus();
      }, 0);
    }
  };

  const handleToggleEmojiPicker = () => {
    setEmojiPickerVisible((prev) => !prev);
  };

  const handleSend = async () => {
    if (message.trim() || selectedFiles.length > 0) {
      const roomId = chat?.id ? chat.id : 1;

      // Send message if available
      if (message.trim()) {
        onSendMessage({
          senderId: userData?.data?.id,
          roomId,
          content: message,
        });
      }

      // Process and send selected files as attachments
      for (const file of selectedFiles) {
        const base64String = await convertFileToBase64(file);
        const extension = file.name.split('.').pop() || '';
        const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        onSendAttachment({
          base64String,
          extension,
          fileName,
          roomId,
        });
      }

      // Clear input fields after sending
      setMessage('');
      setSelectedFiles([]);
      setEmojiPickerVisible(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      handleTypingStop();
    }
  };

  const handleTextFieldClick = () => {
    if (textFieldRef.current) {
      setCursorPosition(textFieldRef.current.selectionStart);
    }
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = event.target.value;
    const cursorPos = textFieldRef.current?.selectionStart || newMessage.length;
    setMessage(newMessage);
    setCursorPosition(cursorPos);

    // Trigger typing start if typing has just begun
    if (newMessage) {
      handleTypingStart();

      // Reset the inactivity timer
      if (typingTimerRef.current) {
        clearTimeout(typingTimerRef.current);
      }

      // Set a timer to trigger handleTypingStop after 2 seconds of inactivity
      typingTimerRef.current = setTimeout(() => {
        handleTypingStop();
      }, 10000);
    } else {
      // Stop typing indication if input is cleared
      handleTypingStop();
    }
  };

  // Clean up timers on component unmount
  useEffect(() => {
    return () => {
      if (typingTimerRef.current) {
        clearTimeout(typingTimerRef.current);
      }
      handleTypingStop();
    };
  }, [handleTypingStop]);

  const handleTextFieldKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setPopoverOpen(false);
    }
  };

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      borderLeft='2px solid lightgray'
      alignItems='center'
    >
      <TypingIndicator
        typingUsers={typingUsers.filter(
          (user) => user.roomId === chat?.id && user.userId !== userData?.data?.id && user.typing,
        )}
      />
      <Dialog
        open={PopoverOpen}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            padding: 10,
            borderRadius: '8px',
            width: '100%',
            backgroundColor: 'white',
          },
        }}
      >
        <IconButton
          onClick={() => handlePopoverClose({} as React.MouseEvent<HTMLElement>)}
          aria-label='close'
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: 'grey.500',
            '&:hover': {
              color: 'grey.700',
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Typography sx={{ color: 'black', textAlign: 'center' }}>
            File <strong>{errorMessage}</strong> exceeds 10MB limit. Upload files less than 10MB.
          </Typography>
        </DialogContent>
      </Dialog>
      {selectedFiles.length > 0 && (
        <Box
          display='flex'
          gap={2}
          p={1}
          border='2px solid lightgray'
          borderRadius={1}
          bgcolor='background.paper'
          zIndex={1}
          maxWidth='800px'
          maxHeight='200px'
          sx={{ overflowX: 'auto', marginBottom: '10px' }}
        >
          <Box display='flex' gap={2}>
            {selectedFiles.map((file, index) => (
              <Box key={index} display='flex' alignItems='center' gap={2} position='relative'>
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    width='auto'
                    height='160'
                    style={{ objectFit: 'cover', borderRadius: '3px' }}
                  />
                ) : (
                  <Box display='flex' alignItems='center'>
                    <AttachFileIcon />
                    <Box ml={1}>{file.name}</Box>
                  </Box>
                )}
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: -6,
                    right: -6,
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                    boxShadow: 2,
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                  onClick={() => handleRemoveFile(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              variant='contained'
              sx={{
                height: '160px',
                color: 'white',
                bgcolor: '#D3D3D3',
                '&:hover': {
                  bgcolor: '#C0C0C0',
                },
              }}
              onClick={() => fileInputRef.current?.click()}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
      )}
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        gap='6'
        justifyContent='space-evenly'
        alignItems='center'
      >
        {selectedMessage && action === 'reply' && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignContent='center'
            width='100%'
            bgcolor='#f0f0f0'
            p={2}
            mb={1}
            borderRadius='8px'
            border='1px solid #ccc'
            position='relative'
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='caption' color='textSecondary'>
                Replying to: {selectedMessage.senderId}
              </Typography>
              <Typography variant='body2' color='textPrimary'>
                {selectedMessage.content.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </div>

            <IconButton size='small' sx={{}} onClick={() => dispatch(setSelectedMessage(null))}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
        )}
        <Box width='100%' display='flex' gap='6' justifyContent='space-evenly' alignItems='center'>
          <IconButton sx={{ color: 'black' }}>
            <KeyboardVoiceIcon />
          </IconButton>
          <TextField
            id='outlined'
            size='small'
            sx={{ width: '87%' }}
            placeholder='Type Your Message'
            variant='outlined'
            value={message}
            onChange={handleTextFieldChange}
            onClick={handleTextFieldClick}
            inputRef={textFieldRef}
            onKeyPress={handleTextFieldKeyPress}
            multiline
            minRows={1}
            maxRows={5}
          />
          <Box position='relative'>
            <IconButton
              ref={emojiButtonRef}
              sx={{ color: 'black' }}
              onClick={handleToggleEmojiPicker}
            >
              <EmojiEmotionsIcon />
            </IconButton>
            {emojiPickerVisible && (
              <Box sx={{ position: 'absolute', bottom: '45px', right: '5px', zIndex: 2 }}>
                <Picker onEmojiSelect={handleEmojiSelect} theme='light' />
              </Box>
            )}
          </Box>
          <IconButton sx={{ color: 'black' }} onClick={() => fileInputRef.current?.click()}>
            <AttachFileIcon />
          </IconButton>
          <input
            ref={fileInputRef}
            type='file'
            multiple
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
          <IconButton sx={{ color: 'black' }} onClick={handleSend}>
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatInputArea;
