import React from 'react';
import { Box, Typography, styled, keyframes } from '@mui/material';
import { TypingUser } from '../../store/constants/constants';

interface TypingIndicatorProps {
  typingUsers: TypingUser[];
}

// Define bounce animation
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

// Styled components
const DotContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
}));

const Dot = styled(Box)<{ delay: number }>(({ theme, delay }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[400],
  animation: `${bounce} 1s infinite`,
  animationDelay: `${delay}ms`,
}));

const IndicatorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1, 2),
  maxWidth: 'fit-content',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[100],
  alignSelf: 'flex-start',
}));

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ typingUsers }) => {
  if (typingUsers.length === 0) return null;

  const getTypingText = () => {
    // Get first names only
    const firstNames = typingUsers.map((user) => user.userName.split(' ')[0]);

    if (firstNames.length === 1) {
      return `${firstNames[0]} is typing...`;
    } else if (firstNames.length === 2) {
      return `${firstNames[0]} and ${firstNames[1]} are typing...`;
    } else {
      return `${firstNames.length} people are typing...`;
    }
  };

  return (
    <IndicatorContainer>
      <Typography
        variant='body2'
        color='text.secondary'
        sx={{
          ml: 0,
          maxWidth: '250px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {getTypingText()}
      </Typography>

      <DotContainer>
        <Dot delay={0} />
        <Dot delay={200} />
        <Dot delay={400} />
      </DotContainer>
    </IndicatorContainer>
  );
};

export default React.memo(TypingIndicator);
