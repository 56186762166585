import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import CloseIcon from '@mui/icons-material/Close';
import { MessageData } from '../../store/constants/constants';

interface PinnedMessageProps {
  pinnedMessage: {
    id: number;
    message: MessageData;
  } | null;
  onUnpin: (id: number) => void;
  onScroll: () => void;
}

const PinnedMessage: React.FC<PinnedMessageProps> = ({ pinnedMessage, onUnpin, onScroll }) => {
  if (!pinnedMessage) return null;

  const getFileName = (path: string) => {
    return path.split('/').pop() || 'Unnamed file';
  };

  const handleUnpin = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent onScroll from firing
    if (pinnedMessage.id) {
      onUnpin(pinnedMessage.id);
    }
  };

  const handleClick = () => {
    onScroll();
  };

  const renderAttachment = (message: MessageData) => {
    if (!message.path) return null;

    const fileName = getFileName(message.path);

    // Handle image attachments
    if (/\.(jpg|jpeg|png|gif)$/i.test(message.path)) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <img
            src={`https://chatroom.enitiation.com/${message.path}`}
            alt='Pinned attachment'
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
              objectFit: 'cover',
              borderRadius: '4px',
            }}
          />
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {fileName}
          </Typography>
        </Box>
      );
    }

    // Handle video attachments
    if (/\.(mp4|mov|avi|mkv)$/i.test(message.path)) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: '30px',
              height: '30px',
              bgcolor: 'action.selected',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='caption'>Vid</Typography>
          </Box>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {fileName}
          </Typography>
        </Box>
      );
    }

    // Handle PDF attachments
    if (/\.(pdf)$/i.test(message.path)) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <img
            src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
            alt='PDF Icon'
            style={{
              width: '30px',
              height: '30px',
            }}
          />
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {fileName}
          </Typography>
        </Box>
      );
    }

    // Handle Word documents
    if (/\.(docx|doc)$/i.test(message.path)) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <img
            src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png'
            alt='Word Icon'
            style={{
              width: '30px',
              height: '30px',
            }}
          />
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {fileName}
          </Typography>
        </Box>
      );
    }

    // Handle text files
    if (/\.(txt)$/i.test(message.path)) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: '30px',
              height: '30px',
              bgcolor: 'action.selected',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='caption'>TXT</Typography>
          </Box>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {fileName}
          </Typography>
        </Box>
      );
    }

    // Default file attachment display
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            width: '30px',
            height: '30px',
            bgcolor: 'action.selected',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='caption'>File</Typography>
        </Box>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Attachment
        </Typography>
      </Box>
    );
  };

  const renderMessageContent = () => {
    const message = pinnedMessage.message;

    if (message.type === 'attachments') {
      return renderAttachment(message);
    }

    if (message.type === 'forwardMessage') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='caption' sx={{ color: 'text.secondary' }}>
            Forwarded message
          </Typography>
          {message.path ? (
            renderAttachment(message)
          ) : (
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              {message.content}
            </Typography>
          )}
        </Box>
      );
    }

    if (message.type === 'replyMessage') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='caption' sx={{ color: 'text.secondary' }}>
            Reply to: {message.repliedTo?.content}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.primary' }}>
            {message.content}
          </Typography>
        </Box>
      );
    }

    return (
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
        {message.content}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: '#f0f0f0',
        p: 1,
        mb: 0.5,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
        '&:hover': {
          bgcolor: '#e0e0e0',
        },
      }}
      onClick={handleClick}
    >
      <PushPinIcon
        fontSize='medium'
        sx={{
          mr: 2,
          bgcolor: 'white',
          borderRadius: '100px',
          p: 0.5,
          color: 'primary.main',
        }}
      />

      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Box
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 1,
            lineClamp: 1,
          }}
        >
          {renderMessageContent()}
        </Box>
      </Box>

      <IconButton
        size='small'
        onClick={handleUnpin}
        sx={{
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </Box>
  );
};

export default PinnedMessage;
