/* eslint-disable react/prop-types */
import { Avatar, Box, Typography } from '@mui/material';
import Setting from './Setting';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import AudioCall from './AudioCall';
import VideoCall from './VideoCall';
import ScreenShare from './ScreenShare';
import isMobile from '../../hooks/isMobile';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { RoomData } from '../../store/constants/constants';
import { useContext, useState } from 'react';
import { UserDataContext } from '../../routes/Main';
import Search from './Search';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

interface ChatBoxHeadingProps {
  chat: RoomData | null;
  onBackClick: () => void;
  onSearchClick: () => void;
  setShowPinnedContext: (show: boolean) => void;
}

const ChatBoxHeading: React.FC<ChatBoxHeadingProps> = ({
  chat,
  onBackClick,
  onSearchClick,
  setShowPinnedContext,
}) => {
  const mobileView = isMobile();
  const { userData } = useContext(UserDataContext);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);

  const getInitials = (name: string) => {
    const parts = name.trim().split(/\s+/);

    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  const handleNameClick = () => {
    setIsSettingsOpen(true);
  };

  return (
    <>
      <Box
        width='100%'
        borderRadius={mobileView ? '0' : '15px'}
        height={mobileView ? '50px' : '25px'}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        zIndex={3}
      >
        <Box display='flex' sx={{ paddingLeft: '6px', cursor: 'pointer' }}>
          {mobileView && <ArrowBackIosIcon onClick={onBackClick} />}
          <Box
            display='flex'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={handleNameClick}
          >
            {chat?.roomType === 'group' ? (
              <>
                <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: '600' }}>
                  {chat?.roomName}
                </Typography>
                <Typography variant='body2' color='text.secondary' sx={{ ml: 1 }}>
                  ({currentRoom?.roomParticipants.length} participants)
                </Typography>
              </>
            ) : (
              <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: '600' }}>
                {currentRoom?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id,
                )?.user?.userName || 'Unknown User'}
              </Typography>
            )}
          </Box>
        </Box>

        {chat?.roomType === 'group' ? (
          <Avatar
            src={`https://chatroom.enitiation.com/${chat?.avatar}`}
            alt='dtvu'
            sx={{
              alignSelf: 'center',
              width: '52px',
              height: '52px',
              marginTop: '30px',
              boxShadow: '0 0 4px 4px #fff, 0 0 4px 4px #fff',
            }}
          >
            {getInitials(chat?.roomName || 'Group Chat')}
          </Avatar>
        ) : (
          <StyledBadge
            sx={{
              '& .MuiBadge-badge': {
                color: chat?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id,
                )?.user?.isActive
                  ? '#28a745'
                  : '#808080',
                backgroundColor: chat?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id,
                )?.user?.isActive
                  ? '#28a745'
                  : '#808080',
                width: '10px',
                height: '10px',
                borderRadius: '5px',
              },
              zIndex: '99',
            }}
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant='dot'
          >
            <Avatar
              src={
                currentRoom?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id,
                )?.user?.profilePictureURL
              }
              alt='dtvu'
              sx={{
                alignSelf: 'center',
                width: '52px',
                height: '52px',
                marginTop: '30px',
                boxShadow: '0 0 4px 4px #fff, 0 0 4px 4px #fff',
              }}
            >
              {getInitials(
                currentRoom?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id,
                )?.user?.userName || 'User',
              )}
            </Avatar>
          </StyledBadge>
        )}

        <Box display='flex' gap='15px' mr={2}>
          <Search onClick={onSearchClick} />
          <ScreenShare />
          <AudioCall />
          <VideoCall />
          <Setting
            isOpen={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
            chats={chat}
            setShowPinnedContext={setShowPinnedContext}
          />
        </Box>
      </Box>
    </>
  );
};

export default ChatBoxHeading;
