import React, { useContext, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Avatar,
  Divider,
  List,
  ListItem,
  Checkbox,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RoomData, MessageData } from '../../store/constants/constants';
import { UserDataContext } from '../../routes/Main';

interface ForwardDialogProps {
  open: boolean;
  onClose: () => void;
  message: MessageData | null;
  rooms: RoomData[];
  onForward: (roomId: number, messageId: number) => void;
}

const ForwardDialog: React.FC<ForwardDialogProps> = ({
  open,
  onClose,
  message,
  rooms,
  onForward,
}) => {
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
  const [isForwarding, setIsForwarding] = useState(false);
  const [progress, setProgress] = useState(0);
  const groupChats = rooms.filter((room) => room.roomType === 'group');
  const individualChats = rooms.filter((room) => room.roomType === 'individual');
  const { userData } = useContext(UserDataContext);

  const handleToggleRoom = (roomId: number) => {
    setSelectedRooms((prev) =>
      prev.includes(roomId) ? prev.filter((id) => id !== roomId) : [...prev, roomId],
    );
  };

  const handleForwardMessages = async () => {
    if (message?.id && selectedRooms.length > 0) {
      setIsForwarding(true);
      try {
        for (let i = 0; i < selectedRooms.length; i++) {
          await onForward(selectedRooms[i], message.id);
          setProgress(((i + 1) / selectedRooms.length) * 100);
        }
        setSelectedRooms([]);
        onClose();
      } catch (error) {
        console.error('Error forwarding messages:', error);
      } finally {
        setIsForwarding(false);
        setProgress(0);
      }
    }
  };

  const renderMessagePreview = (message: MessageData | null) => {
    if (!message) return null;

    switch (message.type) {
      case 'text_messages':
        return (
          <Typography
            sx={{
              bgcolor: '#f5f5f5',
              p: 2,
              borderRadius: 1,
              mb: 2,
              maxHeight: '100px',
              overflow: 'auto',
            }}
          >
            {message.content}
          </Typography>
        );

      case 'attachments':
        if (message.path && /\.(jpg|jpeg|png|gif)$/i.test(message.path)) {
          return (
            <Box sx={{ mb: 2 }}>
              <img
                src={`https://chatroom.enitiation.com/${message.path}`}
                alt='Attachment preview'
                style={{
                  maxWidth: '100%',
                  maxHeight: '150px',
                  objectFit: 'contain',
                  borderRadius: '4px',
                }}
              />
            </Box>
          );
        } else if (message.path && /\.(pdf)$/i.test(message.path)) {
          return (
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                bgcolor: '#f5f5f5',
                borderRadius: 1,
                p: 1,
              }}
            >
              <img
                src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
                alt='PDF Icon'
                style={{ width: '50px', height: '50px' }}
              />
              <Typography variant='body2' color='text.primary' sx={{ ml: 1 }}>
                {message.path.split('/').pop()}
              </Typography>
            </Box>
          );
        }
        break;

      case 'replyMessage':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant='body2' color='text.secondary' gutterBottom>
              Reply to: {message.repliedTo?.content}
            </Typography>
            <Typography sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1 }}>
              {message.content}
            </Typography>
          </Box>
        );

      default:
        return (
          <Typography sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
            {message.content}
          </Typography>
        );
    }
  };

  const getInitials = (name: string) => {
    const parts = name.split(' ');
    if (parts.length === 1) return parts[0].slice(0, 2).toUpperCase();
    return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
  };

  const renderRoomList = (rooms: RoomData[], title: string) => (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant='body1'
        sx={{
          fontWeight: 500,
          color: 'text.secondary',
          pl: 2,
        }}
      >
        {title}
      </Typography>

      <List>
        {rooms.map((room) => (
          <ListItem
            key={room.id}
            onClick={() => handleToggleRoom(room.id)}
            sx={{
              p: 1.5,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                cursor: 'pointer',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Avatar src={`https://chatroom.enitiation.com/${room.avatar}`} sx={{ mr: 2 }}>
                {room.roomType === 'group'
                  ? getInitials(room.roomName)
                  : getInitials(
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.userName || 'User',
                    )}
              </Avatar>
              <Box>
                <Typography>
                  {room.roomType === 'group'
                    ? room.roomName
                    : room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.userName || 'Unknown User'}
                </Typography>
                {room.roomType === 'group' && (
                  <Typography variant='caption' color='text.secondary'>
                    {room.memberCount} members
                  </Typography>
                )}
              </Box>
            </Box>
            <Checkbox
              checked={selectedRooms.includes(room.id)}
              onChange={() => handleToggleRoom(room.id)}
              onClick={(e) => e.stopPropagation()}
              disabled={isForwarding}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={!isForwarding ? onClose : undefined}
      aria-labelledby='forward-dialog-title'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          width: 400,
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Header */}
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant='h6' component='h2' fontWeight={'bold'}>
            Forward Message
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          disabled={isForwarding}
          size='small'
          sx={{ color: 'text.secondary', position: 'absolute', right: '0', top: '0', p: 1 }}
        >
          <CloseIcon />
        </IconButton>

        <Divider />

        {/* Scrollable Content */}
        <Box sx={{ p: 2, overflowY: 'auto', maxHeight: 'calc(80vh - 140px)' }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant='body2' color='text.secondary' gutterBottom>
              Message to forward:
            </Typography>
            {renderMessagePreview(message)}
          </Box>

          <Typography gutterBottom>Select chats to forward the message:</Typography>

          {individualChats.length > 0 && renderRoomList(individualChats, 'People')}
          {groupChats.length > 0 && renderRoomList(groupChats, 'Groups')}
        </Box>

        {/* Sticky Footer */}
        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
            display: 'flex',
            alignSelf: 'center',
          }}
        >
          {isForwarding && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} />
              <Typography variant='body2'>Forwarding... {Math.round(progress)}%</Typography>
            </Box>
          )}
          <Button
            onClick={handleForwardMessages}
            variant='contained'
            disabled={selectedRooms.length === 0 || isForwarding}
            sx={{ ml: 'auto' }}
          >
            Forward to {selectedRooms.length} {selectedRooms.length === 1 ? 'chat' : 'chats'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForwardDialog;
