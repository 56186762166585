import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  List,
  ListItem,
  Avatar,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Input,
  ListItemIcon,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { StyledBadge } from './Setting';
import { updateRoom } from '../../store/room/roomAction';

interface CreateGroupDialogProps {
  open: boolean;
  onClose: () => void;
}

interface AvatarInfo {
  base64String: string;
  fileName: string;
  extension: string;
  dataUrl: string;
}

const UpdateRoomDialog: React.FC<CreateGroupDialogProps> = ({ open, onClose }) => {
  const [groupName, setGroupName] = useState('');
  const [groupAvatar, setGroupAvatar] = useState<AvatarInfo | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const socket = useContext(SocketContext);
  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);

  // Initials for avatar
  const getInitials = (name: string) => {
    const parts = name.trim().split(/\s+/);

    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target?.result as string;
        // Extract the base64 data from the data URL
        const base64String = dataUrl.split(',')[1];
        const fullName = file.name;
        const fileName = fullName.substring(0, fullName.lastIndexOf('.'));
        const extension = fullName.split('.').pop() || '';
        setGroupAvatar({ dataUrl, base64String, fileName, extension });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateRoom = () => {
    if (currentRoom && (groupName || groupAvatar) && socket) {
      const payload = {
        id: currentRoom.id,
        roomName: groupName || currentRoom.roomName,
        // Only include avatar data if a new avatar was uploaded
        ...(groupAvatar
          ? {
              base64String: groupAvatar.base64String,
              fileName: groupAvatar.fileName,
              extension: groupAvatar.extension,
            }
          : {
              // If no new avatar, don't send avatar-related fields
              base64String: undefined,
              fileName: undefined,
              extension: undefined,
            }),
      };
      dispatch(updateRoom({ socket, payload }))
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch((error) => {
          console.error('Failed to update room:', error);
        });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant='h6' component='span' sx={{ fontWeight: 'bold' }}>
          Update Room details
        </Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Group avatar and name input */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
          <IconButton component='label' sx={{ p: 0 }}>
            <Avatar
              sx={{ width: 80, height: 80, mb: 1 }}
              src={
                groupAvatar?.dataUrl ||
                `https://chatroom.enitiation.com/${currentRoom?.avatar}` ||
                undefined
              }
            >
              {currentRoom?.roomName ? getInitials(currentRoom.roomName) : 'G'}
            </Avatar>
            <Input
              type='file'
              onChange={handleAvatarChange}
              sx={{ display: 'none' }}
              inputProps={{ accept: 'image/*' }}
            />
          </IconButton>
          <TextField
            placeholder={currentRoom?.roomName}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            variant='standard'
            sx={{ width: '30%' }}
            inputProps={{
              sx: {
                textAlign: 'center',
              },
            }}
          />
        </Box>

        <Typography variant='subtitle1' sx={{ mb: 1, ml: 1 }}>
          Participants
        </Typography>

        {/* User list */}
        <List sx={{ maxHeight: 200, overflow: 'auto' }}>
          {currentRoom?.roomParticipants.map((participant) => (
            <ListItem
              key={participant.user.userId}
              disablePadding
              sx={{
                borderBottom: '2px solid lightgray',

                '&:hover': {
                  backgroundColor: 'grey.300', // Darker shade from theme palette
                },
              }}
            >
              <ListItemIcon>
                <StyledBadge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant='dot'
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: participant.user.isActive ? '#28a745' : '#000',
                      color: participant.user.isActive ? '#28a745' : '#000',
                    },
                  }}
                >
                  <Avatar
                    src={participant.user.profilePictureURL}
                    alt={participant.user.userName}
                    sx={{ width: 45, height: 45, ml: 1 }}
                  >
                    {getInitials(participant.user.userName || 'Anonymous')}
                  </Avatar>
                </StyledBadge>
              </ListItemIcon>
              <ListItemText primary={participant.user.userName} secondary={participant.userRole} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={handleUpdateRoom}
          disabled={!groupName && !groupAvatar}
        >
          Update Details
          <IconButton>
            <GroupAddIcon sx={{ color: groupName || groupAvatar ? 'white' : 'grey.500' }} />
          </IconButton>
        </Button>
      </Box>
    </Dialog>
  );
};

export default UpdateRoomDialog;
