import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { createRoom, fetchRoomDetails } from '../../store/room/roomAction';
import { SocketContext } from '../../utils/SocketProvider';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  IconButton,
  Box,
  Typography,
  Input,
  ListItemButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { sendChatLogAction } from '../../store/message/messageActions';
import { debounce } from 'lodash';
import { UserDataContext } from '../../routes/Main';

interface CreateGroupDialogProps {
  open: boolean;
  onClose: () => void;
}

interface AvatarInfo {
  base64String: string;
  fileName: string;
  extension: string;
  dataUrl: string;
}

const CreateGroupDialog: React.FC<CreateGroupDialogProps> = ({ open, onClose }) => {
  const [groupName, setGroupName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [groupAvatar, setGroupAvatar] = useState<AvatarInfo | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const socket = useContext(SocketContext);
  const users = useSelector((state: RootState) => state.user.users);
  const { userData } = useContext(UserDataContext);

  // Initials for avatar
  const getInitials = (name: string) => {
    const parts = name.trim().split(/\s+/);

    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  const handleUserToggle = (userId: number) => {
    setSelectedUserIds((prev) =>
      prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId],
    );
  };

  const handleRemoveUser = (userId: number) => {
    setSelectedUserIds((prev) => prev.filter((id) => id !== userId));
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target?.result as string;
        // Extract the base64 data from the data URL
        const base64String = dataUrl.split(',')[1];
        const fullName = file.name;
        const fileName = fullName.substring(0, fullName.lastIndexOf('.'));
        const extension = fullName.split('.').pop() || '';
        setGroupAvatar({ dataUrl, base64String, fileName, extension });
      };
      reader.readAsDataURL(file);
    }
  };

  const selectedChat = useSelector((state: RootState) => state.room.selectedChat);
  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);

  const fetchRoomDetailsDebounced = debounce(() => {
    if (selectedChat?.id && socket) {
      dispatch(fetchRoomDetails({ socket, payload: { id: selectedChat.id } }));
    }
  }, 1000);
  useEffect(() => {
    fetchRoomDetailsDebounced();

    // Cleanup the debounce on unmount
    return () => {
      fetchRoomDetailsDebounced.cancel();
    };
  }, [selectedChat?.id, dispatch, socket, currentRoom]);

  // Dispatch creategroup

  const handleCreateGroup = async () => {
    if (groupName && selectedUserIds.length > 0 && socket) {
      const payload = {
        participantIds: selectedUserIds,
        roomName: groupName,
        base64String: groupAvatar?.base64String,
        fileName: groupAvatar?.fileName,
        extension: groupAvatar?.extension,
        roomType: 'group' as const,
      };

      try {
        // Await the creation and unwrapping of the new room
        const newRoom = await dispatch(createRoom({ socket, payload })).unwrap();

        if (newRoom) {
          dispatch(
            sendChatLogAction({
              socket: socket,
              logData: {
                roomId: newRoom?.id,
                content: `${userData?.data?.name} created the group ${newRoom?.roomName}`,
              },
            }),
          );
        }

        setSelectedUserIds([]);
        setGroupName('');
        setGroupAvatar(null);
        onClose();
      } catch (error) {
        console.error('Failed to create the room:', error);
      }
    }
  };

  const filteredUsers = users
    .filter((user) => user.userId !== userData.data.id)
    .filter((user) => user.userName.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant='h6' component='span' sx={{ fontWeight: 'bold' }}>
          Create Group
        </Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Group avatar and name input */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
          <IconButton component='label' sx={{ p: 0 }}>
            <Avatar sx={{ width: 80, height: 80, mb: 1 }} src={groupAvatar?.dataUrl || undefined}>
              {!groupAvatar && 'G'}
            </Avatar>
            <Input
              type='file'
              onChange={handleAvatarChange}
              sx={{ display: 'none' }}
              inputProps={{ accept: 'image/*' }}
            />
          </IconButton>
          <TextField
            placeholder='Group Name'
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            variant='standard'
            sx={{ width: '30%' }}
            inputProps={{
              sx: {
                textAlign: 'center',
              },
            }}
          />
        </Box>

        {/* Search input */}
        <TextField
          fullWidth
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          sx={{ mb: 1 }}
        />

        {/* Selected users */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', mb: 2 }}>
          {selectedUserIds.map((userId) => {
            const user = users.find((u) => u.userId === userId);
            if (!user) return null;

            return (
              <Box
                key={userId}
                sx={{
                  position: 'relative',
                  margin: 0.5,
                }}
              >
                <Avatar sx={{ height: 45, width: 45 }}>
                  {getInitials(user.userName || 'User')}
                </Avatar>
                <IconButton
                  size='small'
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '2px',
                  }}
                  onClick={() => handleRemoveUser(userId)}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            );
          })}
        </Box>

        <Typography variant='subtitle1' sx={{ mb: 1 }}>
          Suggested
        </Typography>

        {/* User list */}
        <List sx={{ maxHeight: 200, overflow: 'auto' }}>
          {filteredUsers.map((user) => (
            <ListItemButton
              key={user.userId}
              dense
              onClick={() => handleUserToggle(user.userId)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ height: 45, width: 45 }}>
                  {getInitials(user.userName || 'User')}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user.userName}
                // secondary={`ID: ${user.userId}`}
              />
              <Checkbox
                edge='end'
                checked={selectedUserIds.includes(user.userId)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleUserToggle(user.userId);
                }}
                onClick={(e) => e.stopPropagation()} // Add this to prevent double-firing
              />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={handleCreateGroup}
          disabled={!groupName || selectedUserIds.length === 0}
        >
          Create Group
          <IconButton>
            <GroupAddIcon
              sx={{ color: groupName && selectedUserIds.length > 0 ? 'white' : 'grey.500' }}
            />
          </IconButton>
        </Button>
      </Box>
    </Dialog>
  );
};

export default CreateGroupDialog;
