import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import ChatBoxHeading from './ChatBoxHeading';
import ChatArea from './ChatArea';
import ChatInputArea from './ChatInputArea';
import isMobile from '../../hooks/isMobile';
import { MessageData, RoomData } from '../../store/constants/constants';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from '../../utils/SocketProvider';
import {
  replyMessageAction,
  sendMessageAction,
  sendAttachmentAction,
  editMessageAction,
} from '../../store/message/messageActions';
import { setSearchStatus, setSelectedMessage } from '../../store/message/messageSlice';
import { setSelectedChat } from '../../store/room/roomSlice';

interface ChatBoxProps {
  chat: RoomData | null;
}

const ChatBox: React.FC<ChatBoxProps> = ({ chat }) => {
  const mobileView = isMobile();
  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const selectedMessage = useSelector((state: RootState) => state.message.selectedMessage);
  const action = useSelector((state: RootState) => state.message.action);
  const [droppedFiles, setDroppedFiles] = useState<FileList | null>(null);
  const handleFileDrop = (files: FileList) => {
    setDroppedFiles(files); // Store dropped files
  };

  const [showPinnedContext, setShowPinnedContext] = useState(false);

  const addMessage = (message: MessageData) => {
    if (socket) {
      if (selectedMessage && selectedMessage?.id && action === 'reply') {
        // Reply message handling
        const updatedMessage = {
          ...message,
          messageId: selectedMessage?.id,
        };
        dispatch(
          replyMessageAction({
            socket,
            reply: updatedMessage,
          }),
        );
        dispatch(setSelectedMessage(null));
      } else if (selectedMessage && selectedMessage?.id && action === 'edit') {
        const updatedMessage = {
          ...message,
          id: selectedMessage?.id,
        };
        dispatch(
          editMessageAction({
            socket,
            edit: updatedMessage,
          }),
        );
      } else {
        // Regular message handling
        dispatch(
          sendMessageAction({
            socket,
            message: message,
          }),
        );
      }
    }
    // Update the message state
  };

  const addAttachment = (attachment: {
    base64String: string;
    fileName: string;
    extension: string;
    roomId: number;
  }) => {
    if (socket) {
      dispatch(
        sendAttachmentAction({
          socket,
          attachment: {
            ...attachment,
          },
        }),
      );
    }
  };

  const handleBackClick = () => {
    dispatch(setSelectedChat(null)); // Reset selected chat to null
  };

  if (!chat) {
    return <div style={{ margin: 'auto' }}>Select a chat to start messaging</div>;
  }

  // Toggle search bar visibility
  const handleSearchClick = () => {
    dispatch(setSearchStatus(true));
  };

  const handleCloseSearch = () => {
    dispatch(setSearchStatus(false));
  };

  return (
    <Box
      width='100%'
      bgcolor='white'
      borderRadius={mobileView ? '0' : '20px'}
      height={mobileView ? '100vh' : '97dvh'}
      display='flex'
      position='relative'
      alignItems='center'
      flexDirection='column'
      justifyContent='space-between'
    >
      <ChatBoxHeading
        chat={chat}
        onBackClick={handleBackClick}
        onSearchClick={handleSearchClick}
        setShowPinnedContext={setShowPinnedContext}
      />

      {mobileView ? (
        <Box sx={{ flex: 1, width: '100%', overflowY: 'auto' }}>
          <ChatArea
            chat={chat}
            onCloseSearch={handleCloseSearch}
            onFileDrop={handleFileDrop}
            showPinnedContext={showPinnedContext}
            setShowPinnedContext={setShowPinnedContext}
          />
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            width: '100%',
            borderTop: '2px solid lightgray',
            borderLeft: '2px solid lightgray',
            borderRadius: '3px 0 0 0',
            overflowY: 'auto',
          }}
        >
          {/* showSearch={showSearch} */}
          <ChatArea
            chat={chat}
            onCloseSearch={handleCloseSearch}
            onFileDrop={handleFileDrop}
            showPinnedContext={showPinnedContext}
            setShowPinnedContext={setShowPinnedContext}
          />
        </Box>
      )}

      <ChatInputArea
        chat={chat}
        droppedFiles={droppedFiles}
        onSendMessage={addMessage}
        onSendAttachment={addAttachment}
      />
    </Box>
  );
};

export default ChatBox;
