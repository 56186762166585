import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import { CustomTabPanel } from '../../components/Attendance/Report';
import isMobile from '../../hooks/isMobile';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import { fetchUsers } from '../../store/user/userActions';
import CreateGroupDialog from './CreateGroupDialog';
import {
  createRoom,
  fetchRoomsByUser,
  searchRooms,
} from '../../store/room/roomAction';
import { UserDataContext } from '../../routes/Main';
import { RoomData } from '../../store/constants/constants';
import ChatItem from './ChatItem';
import { debounce } from 'lodash';

interface ChatListProps {
  onChatItemClick: (chat: RoomData) => void;
}

// Initials for avatar
const getInitials = (name: string) => {
  const parts = name.trim().split(/\s+/);

  if (parts.length === 1) {
    return parts[0].slice(0, 2).toUpperCase();
  }
  return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
};

const ChatList: React.FC<ChatListProps> = (props: ChatListProps) => {
  const mobileView = isMobile();
  const { userData } = useContext(UserDataContext);

  // Group add dialogue
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);

  const handleOpenCreateGroupDialog = () => {
    setCreateGroupDialogOpen(true);
  };

  const handleCloseCreateGroupDialog = () => {
    setCreateGroupDialogOpen(false);
  };

  const [filterParam, setFilterParam] = useState<string>('recent');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterRoom = (filterBy: string) => {
    setFilterParam(filterBy);
    if (socket) {
      dispatch(fetchRoomsByUser({ socket, userId: userData.data.id, filterBy }));
    }
    setAnchorEl(null);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (socket) {
      dispatch(fetchUsers({ socket }));
    }
  }, [dispatch, socket]);

  // Fetch rooms by userId (Assuming a static userId for now)
  const rooms = useSelector((state: RootState) => state.room.rooms);
  const searchedRooms = useSelector((state: RootState) => state.room.searchedRooms);

  const debouncedDispatch = debounce(() => {
    if (socket) {
      dispatch(fetchRoomsByUser({ socket, userId: userData?.data?.id, filterBy: filterParam }));
    }
  }, 1000);

  useEffect(() => {
    debouncedDispatch();

    // Cleanup the debounce on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, socket, rooms]);

  const flattenedRooms = rooms.flat();

  const membersRooms = flattenedRooms.filter((room) => room.roomType === 'individual');
  const groupRooms = flattenedRooms.filter((room) => room.roomType === 'group');
  const [isSearching, setIsSearching] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (userId: number, query: string) => {
    if (query.trim()) {
      setIsSearching(true);
      if (socket) {
        dispatch(searchRooms({ socket, userId, query }));
      }
    } else {
      setIsSearching(false);
    }
  };

  return (
    <>
      <Box
        maxHeight={mobileView ? '87vh' : '89vh'}
        width='100%'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '15px',
        }}
      >
        {/* Search Input */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            placeholder='Search'
            variant='outlined'
            size='small'
            inputRef={searchInputRef}
            sx={{
              width: mobileView ? 'auto' : '100%',
              borderRadius: '15px',
              padding: mobileView ? '5px 20px' : '0',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Search sx={{ color: 'action.active', pointerEvents: 'none' }} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleSearch(userData?.data?.id, e.target.value)}
          />
          <IconButton
            size='small'
            sx={{ color: 'text.secondary', padding: '0px' }}
            onClick={handleMenuOpen}
          >
            <MoreVertIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => handleFilterRoom('recent')}>Latest Chats</MenuItem>
            <MenuItem onClick={() => handleFilterRoom('unread')}>Unread Chats</MenuItem>
          </Menu>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Tabs value={value} onChange={handleChange} sx={{ width: '100%' }}>
            <Tab
              label='All'
              sx={{
                color: value === 0 ? 'black' : 'lightgray',
                flex: 1,
              }}
              disableRipple
            />
            <Tab
              label='Members'
              sx={{
                color: value === 1 ? 'black' : 'lightgray',
                flex: 1,
              }}
              disableRipple
            />
            <Tab
              label='Groups'
              sx={{
                color: value === 2 ? 'black' : 'lightgray',
                flex: 1,
              }}
              disableRipple
            />
          </Tabs>
        </Box>
        {/* All chat list */}
        <CustomTabPanel value={value} index={0}>
          <List>
            {isSearching ? (
              // Show search results if searching
              <div>
                {/* Display New Users */}
                <h3>New Users</h3>
                {searchedRooms[0]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group' ? room.roomName : room.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => {
                      if (userData?.data?.id && room?.userId && socket) {
                        const participantIds = [room.userId];

                        const payload = {
                          participantIds,
                          roomType: 'individual' as const,
                        };

                        dispatch(
                          createRoom({
                            socket,
                            payload,
                          }),
                        );

                        if (searchInputRef.current) {
                          searchInputRef.current.value = '';
                          setIsSearching(false);
                        }
                      }
                    }}
                  />
                ))}

                {/* Display Individual Chats */}
                <h3>Individual Chats</h3>
                {searchedRooms[1]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                ))}

                {/* Display Group Chats */}
                <h3>Group Chats</h3>
                {searchedRooms[2]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                ))}
              </div>
            ) : (
              // Show default rooms if not searching
              flattenedRooms.slice(0, 20).map((room: RoomData) => (
                <ChatItem
                  key={room.id}
                  name={
                    room.roomType === 'group'
                      ? room.roomName
                      : room.roomParticipants?.find(
                          (participant) => participant.user.userId !== userData.data.id,
                        )?.user?.userName || 'Unknown User'
                  }
                  avatar={
                    room.roomType === 'group'
                      ? room.avatar || getInitials(room.roomName)
                      : room.roomParticipants?.find(
                          (participant) => participant.user.userId !== userData.data.id,
                        )?.user?.profilePictureURL ||
                        getInitials(
                          room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'User',
                        )
                  }
                  lastmessage={
                    room.lastMessage?.content
                      ? room.lastMessage?.content
                      : room.lastMessage?.forwardMessage?.content
                      ? room.lastMessage?.forwardMessage?.content
                      : room.lastMessage?.forwardMessage?.path
                      ? 'Image'
                      : room.lastMessage?.path
                      ? 'Image'
                      : 'No message'
                  }
                  time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                    'en-US',
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    },
                  )}
                  count={room.unseenMessagesCount}
                  unseen={room.unseenMessagesCount > 0 ? true : false}
                  online={
                    room.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.isActive
                  }
                  onClick={() => props.onChatItemClick(room)}
                />
              ))
            )}
          </List>
        </CustomTabPanel>
        {/* member chat list */}
        <CustomTabPanel value={value} index={1}>
          <List>
            {isSearching ? (
              // Show search results if searching
              <div>
                {/* Display New Users */}
                <h3>New Users</h3>
                {searchedRooms[0]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group' ? room.roomName : room.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => {
                      if (userData?.data?.id && room?.userId && socket) {
                        const participantIds = [room.userId];

                        const payload = {
                          participantIds,
                          roomType: 'individual' as const,
                        };

                        dispatch(
                          createRoom({
                            socket,
                            payload,
                          }),
                        );

                        if (searchInputRef.current) {
                          searchInputRef.current.value = '';
                          setIsSearching(false);
                        }
                      }
                    }}
                  />
                ))}

                {/* Display Individual Chats */}
                <h3>Individual Chats</h3>
                {searchedRooms[1]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                ))}

                {/* Display Group Chats */}
                <h3>Group Chats</h3>
                {searchedRooms[2]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                ))}
              </div>
            ) : (
              membersRooms?.slice(0, 20).map((room: RoomData) => {
                return (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.profilePictureURL ||
                      getInitials(
                        room.roomParticipants?.find(
                          (participant) => participant.user.userId !== userData.data.id,
                        )?.user?.userName || 'User',
                      )
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                );
              })
            )}
          </List>
        </CustomTabPanel>
        {/* groups chat list */}
        <CustomTabPanel value={value} index={2}>
          <List>
            <ListItemButton
              onClick={handleOpenCreateGroupDialog}
              sx={{
                borderBottom: '2px solid lightgray',
                '&:hover': {
                  backgroundColor: 'grey.300',
                },
              }}
            >
              <ListItemIcon>
                <Avatar sx={{ width: 45, height: 45 }}>
                  <AddIcon sx={{ width: 35, height: 35 }} />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary='Create Group' />
            </ListItemButton>
            {isSearching ? (
              // Show search results if searching
              <div>
                {/* Display New Users */}
                <h3>New Users</h3>
                {searchedRooms[0]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group' ? room.roomName : room.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => {
                      if (userData?.data?.id && room?.userId && socket) {
                        const participantIds = [room.userId];

                        const payload = {
                          participantIds,
                          roomType: 'individual' as const,
                        };

                        dispatch(
                          createRoom({
                            socket,
                            payload,
                          }),
                        );

                        if (searchInputRef.current) {
                          searchInputRef.current.value = '';
                          setIsSearching(false);
                        }
                      }
                    }}
                  />
                ))}

                {/* Display Individual Chats */}
                <h3>Individual Chats</h3>
                {searchedRooms[1]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                ))}

                {/* Display Group Chats */}
                <h3>Group Chats</h3>
                {searchedRooms[2]?.slice(0, 20).map((room: RoomData) => (
                  <ChatItem
                    key={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          `https://randomuser.me/api/portraits/lego/${
                            (room.id ?? Math.floor(Math.random() * 10)) % 10
                          }.jpg`
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                ))}
              </div>
            ) : (
              groupRooms?.slice(0, 20).map((room: RoomData) => {
                return (
                  <ChatItem
                    key={room.id}
                    name={room.roomName || 'Group Name'}
                    avatar={room.avatar || getInitials(room.roomName)}
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString(
                      'en-US',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      },
                    )}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    onClick={() => props.onChatItemClick(room)}
                  />
                );
              })
            )}
          </List>
        </CustomTabPanel>
      </Box>
      <CreateGroupDialog open={createGroupDialogOpen} onClose={handleCloseCreateGroupDialog} />
    </>
  );
};

ChatList.propTypes = {
  onChatItemClick: PropTypes.func.isRequired,
};

export default ChatList;
